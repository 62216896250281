import { Container } from "@mui/material";
import React from "react";
import { Footer, Header } from "../components";
import PageTitle from "../components/PageTitle";
import privacyPolicyBg from "../images/privacy-policy.png";
import "../styles/privacy-policy.scss";
import { Hat } from "../components/Header";

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <Hat title="Privacy Policy" />
      <Header />
      <PageTitle
        title="Privacy Policy"
        description={<p>Effective date: 2021-01-01</p>}
        background={privacyPolicyBg}
      />
      <Container fluid>
        <div className="content-description">
          <div className="terms-wrapper">
            <h1>1. Introduction</h1>
            <p>
              Welcome to <strong>Insured Finance</strong>.
              <br />
              <strong>Insured Finance</strong> (“us”, “we”, or “our”) operates{" "}
              <strong>insured.finance</strong> (hereinafter referred to as “
              <strong>Service</strong>”).
              <br />
              Our Privacy Policy governs your visit to{" "}
              <strong>insured.finance</strong>, and explains how we collect,
              safeguard and disclose information that results from your use of
              our
              <br /> Service.
              <br />
              <br /> We use your data to provide and improve Service. By using
              Service, you agree to the collection and use of information in
              accordance with this policy. Unless
              <br /> otherwise defined in this Privacy Policy, the terms used in
              this Privacy Policy have the same meanings as in our Terms and
              Conditions. <br />
              <br /> Our Terms and Conditions (“<strong>Terms</strong>”) govern
              all use of our Service and together with the Privacy Policy
              constitutes your agreement with us (“<strong>agreement</strong>”).
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>2. Definitions</h1>
            <p>
              <strong>SERVICE</strong> means the insured.finance website
              operated by Insured Finance.
              <br />
              <br /> <strong>PERSONAL DATA</strong> means data about a living
              individual who can be identified from those data (or from those
              and other information either in our possession or likely to come
              into our possession).
              <br />
              <br /> <strong>USAGE DATA</strong> is data collected automatically
              either generated by the use of Service or from Service
              infrastructure itself (for example, the duration of a page visit).
              <br />
              <br /> <strong>COOKIES</strong> are small files stored on your
              device (computer or mobile device).
              <br />
              <br />
              <strong>DATA CONTROLLER</strong> means a natural or legal person
              who (either alone or jointly or in common with other persons)
              determines the purposes for which and the manner in which any
              personal data are, or are to be, processed. For the purpose of
              this Privacy Policy, we are a Data Controller of your data.
              <br />
              <br /> <strong>
                DATA PROCESSORS (OR SERVICE PROVIDERS)
              </strong>{" "}
              means any natural or legal person who processes the data on behalf
              of the Data Controller. We may use the services of various Service
              Providers in order to process your data more effectively.
              <br />
              <br /> <strong>DATA SUBJECT</strong> is any living individual who
              is the subject of Personal Data.
              <br />
              <br /> <strong>THE USER</strong> is the individual using our
              Service. The User corresponds to the Data Subject, who is the
              subject of Personal Data.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>3. Information Collection and Use</h1>
            <p>
              We collect several different types of information for various
              purposes to provide and improve our Service to you.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>4. Types of Data Collected</h1>
            <p>
              <strong>Personal Data</strong>
              <br />
              <br /> While using our Service, we may ask you to provide us with
              certain personally identifiable information that can be used to
              contact or identify you (“Personal Data”). Personally identifiable
              information may include, but is not limited to:
              <br />
              <br />
              <ol>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Phone number</li>
                <li>
                  Address, Country, State, Province, ZIP/Postal code, City
                </li>
                <li>Cookies and Usage Data</li>
              </ol>
              <br />
              <br />
              We may use your Personal Data to contact you with newsletters,
              marketing or promotional materials and other information that may
              be of interest to you. You may opt out of receiving any, or all,
              of these communications from us by following the unsubscribe link.
              <br />
              <br /> <strong>Usage Data</strong> <br />
              <br /> We may also collect information that your browser sends
              whenever you visit our Service or when you access Service by or
              through any device (“Usage Data”).
              <br />
              <br /> This Usage Data may include information such as your
              computer’s Internet Protocol address (e.g. IP address), browser
              type, browser version, the pages of our Service that you visit,
              the time and date of your visit, the time spent on those pages,
              unique device identifiers and other diagnostic data.
              <br />
              <br /> When you access Service with a device, this Usage Data may
              include information such as the type of device you use, your
              device unique ID, the IP address of your device, your device
              operating system, the type of Internet browser you use, unique
              device identifiers and other diagnostic data.
              <br />
              <br /> <strong>Tracking Cookies Data</strong>
              <br />
              <br /> We use cookies and similar tracking technologies to track
              the activity on our Service and we hold certain information.
              <br />
              <br />
              Cookies are files with a small amount of data which may include an
              anonymous unique identifier. Cookies are sent to your browser from
              a website and stored on your device. Other tracking technologies
              are also used such as beacons, tags and scripts to collect and
              track information and to improve and analyze our Service.
              <br />
              <br /> You can instruct your browser to refuse all cookies or to
              indicate when a cookie is being sent. However, if you do not
              accept cookies, you may not be able to use some portions of our
              Service.
              <br />
              <br /> Examples of Cookies we use:
              <br />
              <br />
              <ol>
                <li>
                  Session Cookies: We use Session Cookies to operate our
                  Service.
                </li>{" "}
                <li>
                  Preference Cookies: We use Preference Cookies to remember your
                  preferences and various settings.
                </li>{" "}
                <li>
                  Security Cookies: We use Security Cookies for security
                  purposes.
                </li>{" "}
                <li>
                  Advertising Cookies: Advertising Cookies are used to serve you
                  with advertisements that may be relevant to you and your
                  interests.
                </li>
              </ol>
              <br />
              <strong>Other Data</strong>
              <br />
              <br /> While using our Service, we may also collect the following
              information: sex, age, date of birth, place of birth, passport
              details, citizenship, registration at place of residence and
              actual address, telephone number (work, mobile), details of
              documents on education, qualification, professional training,
              employment agreements, information on bonuses and compensation,
              information on marital status, family members, social security (or
              other taxpayer identification) number, office location and other
              data.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>5. Use of Data</h1>
            <p>
              Insured Finance uses the collected data for various purposes:
              <br />
              <br />
              <ol>
                {" "}
                <li>to provide and maintain our Service;</li>{" "}
                <li>to notify you about changes to our Service;</li>{" "}
                <li>
                  to allow you to participate in interactive features of our
                  Service when you choose to do so;
                </li>{" "}
                <li>to provide customer support;</li>{" "}
                <li>
                  to gather analysis or valuable information so that we can
                  improve our Service;
                </li>{" "}
                <li>to monitor the usage of our Service;</li>{" "}
                <li>to detect, prevent and address technical issues;</li>{" "}
                <li>to fulfil any other purpose for which you provide it;</li>{" "}
                <li>
                  to carry out our obligations and enforce our rights arising
                  from any contracts entered into between you and us, including
                  for billing and collection;
                </li>{" "}
                <li>
                  to provide you with notices about your account and/or
                  subscription, including expiration and renewal notices,
                  email-instructions, etc.;
                </li>{" "}
                <li>
                  to provide you with news, special offers and general
                  information about other goods, services and events which we
                  offer that are similar to those that you <br />
                  have already purchased or enquired about unless you have opted
                  not to receive such information;
                </li>{" "}
                <li>
                  in any other way we may describe when you provide the
                  information;
                </li>{" "}
                <li>for any other purpose with your consent.</li>{" "}
              </ol>
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>6. Retention of Data</h1>
            <p>
              We will retain your Personal Data only for as long as is necessary
              for the purposes set out in this Privacy Policy. We will retain
              and use your Personal Data to the extent necessary to comply with
              our legal obligations (for example, if we are required to retain
              your data to comply with applicable laws), resolve disputes, and
              enforce our legal agreements and policies.
              <br />
              <br /> We will also retain Usage Data for internal analysis
              purposes. Usage Data is generally retained for a shorter period,
              except when this data is used to strengthen the security or to
              improve the functionality of our Service, or we are legally
              obligated to retain this data for longer time periods.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>7. Transfer of Data</h1>
            <p>
              Your information, including Personal Data, may be transferred to –
              and maintained on – computers located outside of your state,
              province, country or other governmental jurisdiction where the
              data protection laws may differ from those of your jurisdiction.
              <br />
              <br />
              If you are located outside Eurozone and choose to provide
              information to us, please note that we transfer the data,
              including Personal Data, to Eurozone and process it there.
              <br />
              <br /> Your consent to this Privacy Policy followed by your
              submission of such information represents your agreement to that
              transfer.
              <br />
              <br /> Insured Finance will take all the steps reasonably
              necessary to ensure that your data is treated securely and in
              accordance with this Privacy Policy and no transfer of your
              Personal Data will take place to an organisation or a country
              unless there are adequate controls in place including the security
              of your data and other personal information.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>8. Disclosure of Data</h1>
            <p>
              <ol>
                <li>
                  <strong>Business Transaction.</strong>
                </li>
                If we or our subsidiaries are involved in a merger, acquisition
                or asset sale, your Personal Data may be transferred.{" "}
                <li>
                  <strong>
                    Other cases. We may disclose your information also:
                  </strong>
                  <ol type="a">
                    <li>to our subsidiaries and affiliates;</li>
                    <li>
                      to contractors, service providers, and other third parties
                      we use to support our business;
                    </li>
                    <li>to fulfill the purpose for which you provide it;</li>{" "}
                    <li>
                      for the purpose of including your company’s logo on our
                      website;
                    </li>{" "}
                    <li>
                      for any other purpose disclosed by us when you provide the
                      information;
                    </li>{" "}
                    <li>with your consent in any other cases;</li>{" "}
                    <li>
                      if we believe disclosure is necessary or appropriate to
                      protect the rights, property, or safety of the Company,
                      our customers, or others.
                    </li>
                  </ol>
                </li>
              </ol>
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>9. Security of Data</h1>
            <p>
              The security of your data is important to us but remember that no
              method of transmission over the Internet or method of electronic
              storage is 100% secure. While we strive to use commercially
              acceptable means to protect your Personal Data, we cannot
              guarantee its absolute security.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>
              10. Your Data Protection Rights Under General Data Protection
              Regulation (GDPR)
            </h1>
            <p>
              If you are a resident of the European Union (EU) and European
              Economic Area (EEA), you have certain data protection rights,
              covered by GDPR.
              <br /> We aim to take reasonable steps to allow you to correct,
              amend, delete, or limit the use of your Personal Data.
              <br /> If you wish to be informed what Personal Data we hold about
              you and if you want it to be removed from our systems, please
              email us at
              <br />
              <strong>developer@insured.finance</strong>.<br /> In certain
              circumstances, you have the following data protection rights:
              <br />
              <br />{" "}
              <ol>
                <li>
                  the right to access, update or to delete the information we
                  have on you;
                </li>
                <li>
                  {" "}
                  the right of rectification. You have the right to have your
                  information rectified if that information is inaccurate or
                  incomplete;
                </li>{" "}
                <li>
                  the right to object. You have the right to object to our
                  processing of your Personal Data;
                </li>{" "}
                <li>
                  the right of restriction. You have the right to request that
                  we restrict the processing of your personal information;
                </li>{" "}
                <li>
                  the right to data portability. You have the right to be
                  provided with a copy of your Personal Data in a structured,
                  machine-readable and commonly used format;
                </li>{" "}
                <li>
                  the right to withdraw consent. You also have the right to
                  withdraw your consent at any time where we rely on your
                  consent to process your personal information;
                </li>
              </ol>
              <br />
              <br />
              Please note that we may ask you to verify your identity before
              responding to such requests. Please note, we may not able to
              provide Service without some necessary data.
              <br />
              <br /> You have the right to complain to a Data Protection
              Authority about our collection and use of your Personal Data. For
              more information, please contact your local data protection
              authority in the European Economic Area (EEA).
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>
              11. Your Data Protection Rights under the California Privacy
              Protection Act (CalOPPA)
            </h1>
            <p>
              CalOPPA is the first state law in the nation to require commercial
              websites and online services to post a privacy policy. The law’s
              reach stretches well beyond California to require a person or
              company in the United States (and conceivable the world) that
              operates websites collecting personally identifiable information
              from California consumers to post a conspicuous privacy policy on
              its website stating exactly the information being collected and
              those individuals with whom it is being shared, and to comply with
              this policy.
              <br />
              <br /> According to CalOPPA we agree to the following:
              <br />
              <br />{" "}
              <ol>
                {" "}
                <li>users can visit our site anonymously;</li>{" "}
                <li>
                  our Privacy Policy link includes the word “Privacy”, and can
                  easily be found on the home page of our website;
                </li>{" "}
                <li>
                  users will be notified of any privacy policy changes on our
                  Privacy Policy Page;
                </li>{" "}
                <li>
                  users are able to change their personal information by
                  emailing us at <strong>developer@insured.finance</strong>.
                </li>{" "}
              </ol>
              Our Policy on “Do Not Track” Signals:
              <br />
              <br /> We honor Do Not Track signals and do not track, plant
              cookies, or use advertising when a Do Not Track browser mechanism
              is in place. Do Not Track is a preference you can set in your web
              browser to inform websites that you do not want to be tracked.
              <br />
              <br /> You can enable or disable Do Not Track by visiting the
              Preferences or Settings page of your web browser.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>
              12. Your Data Protection Rights under the California Consumer
              Privacy Act (CCPA)
            </h1>
            <p>
              If you are a California resident, you are entitled to learn what
              data we collect about you, ask to delete your data and not to sell
              (share) it. To exercise your data protection rights, you can make
              certain requests and ask us:
              <br />
              <br />{" "}
              <ol>
                <li>
                  <strong>
                    What personal information we have about you. If you make
                    this request, we will return to you:
                  </strong>
                  <br />
                  <ol type="a">
                    <li>
                      The categories of personal information we have collected
                      about you.
                    </li>
                    <li>
                      The categories of sources from which we collect your
                      personal information.
                    </li>
                    <li>
                      The business or commercial purpose for collecting or
                      selling your personal information.
                    </li>
                    <li>
                      The categories of third parties with whom we share
                      personal information.
                    </li>
                    <li>
                      The specific pieces of personal information we have
                      collected about you.
                    </li>{" "}
                    <li>
                      A list of categories of personal information that we have
                      sold, along with the category of any other company we sold
                      it to. If we have not sold your personal information, we
                      will inform you of that fact.
                    </li>
                    <li>
                      A list of categories of personal information that we have
                      disclosed for a business purpose, along with the category
                      of any other company we shared it with.
                    </li>{" "}
                    <br />
                    Please note, you are entitled to ask us to provide you with
                    this information up to two times in a rolling twelve-month
                    period. When you make this request, the information provided
                    may be limited to the personal information we collected
                    about you in the previous 12 months.
                  </ol>
                  <br />
                </li>
                <li>
                  <strong>
                    To delete your personal information. If you make this
                    request, we will delete the personal information we hold
                    about you as of the date of your request from our records
                    and direct any service providers to do the same. In some
                    cases, deletion may be accomplished through
                    de-identification of the information. If you choose to
                    delete your personal information, you may not be able to use
                    certain functions that require your personal information to
                    operate.
                  </strong>
                </li>
                <li>
                  <strong>
                    To stop selling your personal information. We don’t sell or
                    rent your personal information to any third parties for any
                    purpose. We do not sell your personal information for
                    monetary consideration. However, under some circumstances, a
                    transfer of personal information to a third party, or within
                    our family of companies, without monetary consideration may
                    be considered a “sale” under California law. You are the
                    only owner of your Personal Data and can request disclosure
                    or deletion at any time.
                  </strong>
                </li>
              </ol>
              <br />
              <br />{" "}
              <strong>
                If you submit a request to stop selling your personal
                information, we will stop making such transfers.
              </strong>
              <br />
              <br /> Please note, if you ask us to delete or stop selling your
              data, it may impact your experience with us, and you may not be
              able to participate in certain programs or membership services
              which require the usage of your personal information to function.
              But in no circumstances, we will discriminate against you for
              exercising your rights.
              <br />
              <br /> To exercise your California data protection rights
              described above, please send your request(s) by email:
              developer@insured.finance.
              <br />
              <br /> Your data protection rights, described above, are covered
              by the CCPA, short for the California Consumer Privacy Act. To
              find out more, visit the official California Legislative
              Information website. The CCPA took effect on 01/01/2020.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>13. Service Providers</h1>
            <p>
              We may employ third party companies and individuals to facilitate
              our Service (“Service Providers”), provide Service on our behalf,
              perform Service-related services or assist us in analysing how our
              Service is used.
              <br />
              <br /> These third parties have access to your Personal Data only
              to perform these tasks on our behalf and are obligated not to
              disclose or use it for any other purpose.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>14. Analytics</h1>
            <p>
              We may use third-party Service Providers to monitor and analyze
              the use of our Service.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>15. CI/CD tools</h1>
            <p>
              We may use third-party Service Providers to automate the
              development process of our Service.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>16. Behavioral Remarketing</h1>
            <p>
              We may use remarketing services to advertise on third party
              websites to you after you visited our Service. We and our
              third-party vendors use cookies to inform, optimise and serve ads
              based on your past visits to our Service.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>17. Links to Other Sites</h1>
            <p>
              Our Service may contain links to other sites that are not operated
              by us. If you click a third party link, you will be directed to
              that third party’s site. We strongly advise you to review the
              Privacy Policy of every site you visit.
              <br />
              <br /> We have no control over and assume no responsibility for
              the content, privacy policies or practices of any third party
              sites or services.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>18. Children's Privacy</h1>
            <p>
              Our Services are not intended for use by children under the age of
              18 (“<strong>Child</strong>” or “<strong>Children</strong>”).
              <br />
              <br /> We do not knowingly collect personally identifiable
              information from Children under 18. If you become aware that a
              Child has provided us with Personal Data, please contact us. If we
              become aware that we have collected Personal Data from Children
              without verification of parental consent, we take steps to remove
              that information from our servers.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>19. Changes to This Privacy Policy</h1>
            <p>
              We may update our Privacy Policy from time to time. We will notify
              you of any changes by posting the new Privacy Policy on this page.
              <br />
              <br />
              We will let you know via email and/or a prominent notice on our
              Service, prior to the change becoming effective and update
              “effective date” at the top of this Privacy Policy.
              <br />
              <br /> You are advised to review this Privacy Policy periodically
              for any changes. Changes to this Privacy Policy are effective when
              they are posted on this page.
            </p>
          </div>
          <div className="terms-wrapper">
            <h1>20. Contact Us</h1>
            <p>
              If you have any questions about this Privacy Policy, please
              contact us by email: developer@insured.finance.
            </p>
          </div>
        </div>
      </Container>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
